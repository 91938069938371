<template>
  <section class="text-gray-900 body-font relative">
    <div class="container px-5 py-24 mx-auto">
      <div class="flex flex-col lg:text-center mb-20">
        <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">Resources</h1>
        <p class="lg:w-2/3 mx-auto text-center leading-relaxed text-base">Click from the useful travel resources below.
        </p>
      </div>
      <div class="container px-5 pb-24 mx-auto">
        <div class="flex flex-wrap -m-4 text-xl lg:justify-center mx-auto">
          <div class="lg:p-4 lg:w-1/3 w-full">
            <h2 class="font-medium title-font tracking-widest text-gray-900 mb-4 text-sm text-left"></h2>
            <nav class="flex flex-col items-start text-left -mb-1 space-y-2.5 text-xl font-bold">
              <a href="https://www.ifly.com/" target="_blank">
                <span
                  class="bg-indigo-100 text-purple-500 w-5 h-5 mr-2 rounded-full inline-flex items-center justify-center">
                  <svg aria-hidden="true" style="-ms-transform:rotate(360deg);-webkit-transform:rotate(360deg)"
                    transform="rotate(360)" fill="none" stroke="currentColor" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="3" class="w-3 h-3" viewBox="0 0 1024 1024">
                    <defs />
                    <path fill="#626262"
                      d="M574 665.4a8.03 8.03 0 00-11.3 0L446.5 781.6c-53.8 53.8-144.6 59.5-204 0-59.5-59.5-53.8-150.2 0-204l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3l-39.8-39.8a8.03 8.03 0 00-11.3 0L191.4 526.5c-84.6 84.6-84.6 221.5 0 306s221.5 84.6 306 0l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3L574 665.4zm258.6-474c-84.6-84.6-221.5-84.6-306 0L410.3 307.6a8.03 8.03 0 000 11.3l39.7 39.7c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c53.8-53.8 144.6-59.5 204 0 59.5 59.5 53.8 150.2 0 204L665.3 562.6a8.03 8.03 0 000 11.3l39.8 39.8c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c84.5-84.6 84.5-221.5 0-306.1zM610.1 372.3a8.03 8.03 0 00-11.3 0L372.3 598.7a8.03 8.03 0 000 11.3l39.6 39.6c3.1 3.1 8.2 3.1 11.3 0l226.4-226.4c3.1-3.1 3.1-8.2 0-11.3l-39.5-39.6z" />
                  </svg>
                </span>Airport Terminal Maps
              </a>
              <a href="http://detroitcorporateparking.com/corp/assure-travel/" target="_blank">
                <span
                  class="bg-indigo-100 text-purple-500 w-5 h-5 mr-2 rounded-full inline-flex items-center justify-center">
                  <svg aria-hidden="true" style="-ms-transform:rotate(360deg);-webkit-transform:rotate(360deg)"
                    transform="rotate(360)" fill="none" stroke="currentColor" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="3" class="w-3 h-3" viewBox="0 0 1024 1024">
                    <defs />
                    <path fill="#626262"
                      d="M574 665.4a8.03 8.03 0 00-11.3 0L446.5 781.6c-53.8 53.8-144.6 59.5-204 0-59.5-59.5-53.8-150.2 0-204l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3l-39.8-39.8a8.03 8.03 0 00-11.3 0L191.4 526.5c-84.6 84.6-84.6 221.5 0 306s221.5 84.6 306 0l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3L574 665.4zm258.6-474c-84.6-84.6-221.5-84.6-306 0L410.3 307.6a8.03 8.03 0 000 11.3l39.7 39.7c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c53.8-53.8 144.6-59.5 204 0 59.5 59.5 53.8 150.2 0 204L665.3 562.6a8.03 8.03 0 000 11.3l39.8 39.8c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c84.5-84.6 84.5-221.5 0-306.1zM610.1 372.3a8.03 8.03 0 00-11.3 0L372.3 598.7a8.03 8.03 0 000 11.3l39.6 39.6c3.1 3.1 8.2 3.1 11.3 0l226.4-226.4c3.1-3.1 3.1-8.2 0-11.3l-39.5-39.6z" />
                  </svg>
                </span>Detroit Airport Parking Coupon
              </a>
              <a href="http://www.xe.com/ucc" target="_blank">
                <span
                  class="bg-indigo-100 text-purple-500 w-5 h-5 mr-2 rounded-full inline-flex items-center justify-center">
                  <svg aria-hidden="true" style="-ms-transform:rotate(360deg);-webkit-transform:rotate(360deg)"
                    transform="rotate(360)" fill="none" stroke="currentColor" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="3" class="w-3 h-3" viewBox="0 0 1024 1024">
                    <defs />
                    <path fill="#626262"
                      d="M574 665.4a8.03 8.03 0 00-11.3 0L446.5 781.6c-53.8 53.8-144.6 59.5-204 0-59.5-59.5-53.8-150.2 0-204l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3l-39.8-39.8a8.03 8.03 0 00-11.3 0L191.4 526.5c-84.6 84.6-84.6 221.5 0 306s221.5 84.6 306 0l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3L574 665.4zm258.6-474c-84.6-84.6-221.5-84.6-306 0L410.3 307.6a8.03 8.03 0 000 11.3l39.7 39.7c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c53.8-53.8 144.6-59.5 204 0 59.5 59.5 53.8 150.2 0 204L665.3 562.6a8.03 8.03 0 000 11.3l39.8 39.8c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c84.5-84.6 84.5-221.5 0-306.1zM610.1 372.3a8.03 8.03 0 00-11.3 0L372.3 598.7a8.03 8.03 0 000 11.3l39.6 39.6c3.1 3.1 8.2 3.1 11.3 0l226.4-226.4c3.1-3.1 3.1-8.2 0-11.3l-39.5-39.6z" />
                  </svg>
                </span>Currency Converter
              </a>
              <a href="http://embassyworld.com" target="_blank">
                <span
                  class="bg-indigo-100 text-ipurple-500 w-5 h-5 mr-2 rounded-full inline-flex items-center justify-center">
                  <svg aria-hidden="true" style="-ms-transform:rotate(360deg);-webkit-transform:rotate(360deg)"
                    transform="rotate(360)" fill="none" stroke="currentColor" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="3" class="w-3 h-3" viewBox="0 0 1024 1024">
                    <defs />
                    <path fill="#626262"
                      d="M574 665.4a8.03 8.03 0 00-11.3 0L446.5 781.6c-53.8 53.8-144.6 59.5-204 0-59.5-59.5-53.8-150.2 0-204l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3l-39.8-39.8a8.03 8.03 0 00-11.3 0L191.4 526.5c-84.6 84.6-84.6 221.5 0 306s221.5 84.6 306 0l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3L574 665.4zm258.6-474c-84.6-84.6-221.5-84.6-306 0L410.3 307.6a8.03 8.03 0 000 11.3l39.7 39.7c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c53.8-53.8 144.6-59.5 204 0 59.5 59.5 53.8 150.2 0 204L665.3 562.6a8.03 8.03 0 000 11.3l39.8 39.8c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c84.5-84.6 84.5-221.5 0-306.1zM610.1 372.3a8.03 8.03 0 00-11.3 0L372.3 598.7a8.03 8.03 0 000 11.3l39.6 39.6c3.1 3.1 8.2 3.1 11.3 0l226.4-226.4c3.1-3.1 3.1-8.2 0-11.3l-39.5-39.6z" />
                  </svg>
                </span>Embassy Locator
              </a>
              <a href="http://www.fly.faa.gov/flyfaa/usmap.jsp" target="_blank">
                <span
                  class="bg-indigo-100 text-ipurple-500 w-5 h-5 mr-2 rounded-full inline-flex items-center justify-center">
                  <svg aria-hidden="true" style="-ms-transform:rotate(360deg);-webkit-transform:rotate(360deg)"
                    transform="rotate(360)" fill="none" stroke="currentColor" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="3" class="w-3 h-3" viewBox="0 0 1024 1024">
                    <defs />
                    <path fill="#626262"
                      d="M574 665.4a8.03 8.03 0 00-11.3 0L446.5 781.6c-53.8 53.8-144.6 59.5-204 0-59.5-59.5-53.8-150.2 0-204l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3l-39.8-39.8a8.03 8.03 0 00-11.3 0L191.4 526.5c-84.6 84.6-84.6 221.5 0 306s221.5 84.6 306 0l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3L574 665.4zm258.6-474c-84.6-84.6-221.5-84.6-306 0L410.3 307.6a8.03 8.03 0 000 11.3l39.7 39.7c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c53.8-53.8 144.6-59.5 204 0 59.5 59.5 53.8 150.2 0 204L665.3 562.6a8.03 8.03 0 000 11.3l39.8 39.8c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c84.5-84.6 84.5-221.5 0-306.1zM610.1 372.3a8.03 8.03 0 00-11.3 0L372.3 598.7a8.03 8.03 0 000 11.3l39.6 39.6c3.1 3.1 8.2 3.1 11.3 0l226.4-226.4c3.1-3.1 3.1-8.2 0-11.3l-39.5-39.6z" />
                  </svg>
                </span>Flight Delays
              </a>
            </nav>
          </div>
          <div class="lg:p-4 lg:w-1/3 w-full text-xl">
            <h2 class="font-medium title-font tracking-widest text-gray-900 mb-4 text-sm text-left"></h2>
            <nav class="flex flex-col items-start text-left -mb-1 space-y-2.5 text-xl font-bold">
              <a href="http://www.flightstats.com/go/AirportTracker/airportTracker.do" target="_blank">
                <span
                  class="bg-indigo-100 text-ipurple-500 w-5 h-5 mr-2 rounded-full inline-flex items-center justify-center">
                  <svg aria-hidden="true" style="-ms-transform:rotate(360deg);-webkit-transform:rotate(360deg)"
                    transform="rotate(360)" fill="none" stroke="currentColor" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="3" class="w-3 h-3" viewBox="0 0 1024 1024">
                    <defs />
                    <path fill="#626262"
                      d="M574 665.4a8.03 8.03 0 00-11.3 0L446.5 781.6c-53.8 53.8-144.6 59.5-204 0-59.5-59.5-53.8-150.2 0-204l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3l-39.8-39.8a8.03 8.03 0 00-11.3 0L191.4 526.5c-84.6 84.6-84.6 221.5 0 306s221.5 84.6 306 0l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3L574 665.4zm258.6-474c-84.6-84.6-221.5-84.6-306 0L410.3 307.6a8.03 8.03 0 000 11.3l39.7 39.7c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c53.8-53.8 144.6-59.5 204 0 59.5 59.5 53.8 150.2 0 204L665.3 562.6a8.03 8.03 0 000 11.3l39.8 39.8c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c84.5-84.6 84.5-221.5 0-306.1zM610.1 372.3a8.03 8.03 0 00-11.3 0L372.3 598.7a8.03 8.03 0 000 11.3l39.6 39.6c3.1 3.1 8.2 3.1 11.3 0l226.4-226.4c3.1-3.1 3.1-8.2 0-11.3l-39.5-39.6z" />
                  </svg>
                </span>Flight Tracker
              </a>
              <a href="http://www.flightview.com/" target="_blank">
                <span
                  class="bg-indigo-100 text-ipurple-500 w-5 h-5 mr-2 rounded-full inline-flex items-center justify-center">
                  <svg aria-hidden="true" style="-ms-transform:rotate(360deg);-webkit-transform:rotate(360deg)"
                    transform="rotate(360)" fill="none" stroke="currentColor" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="3" class="w-3 h-3" viewBox="0 0 1024 1024">
                    <defs />
                    <path fill="#626262"
                      d="M574 665.4a8.03 8.03 0 00-11.3 0L446.5 781.6c-53.8 53.8-144.6 59.5-204 0-59.5-59.5-53.8-150.2 0-204l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3l-39.8-39.8a8.03 8.03 0 00-11.3 0L191.4 526.5c-84.6 84.6-84.6 221.5 0 306s221.5 84.6 306 0l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3L574 665.4zm258.6-474c-84.6-84.6-221.5-84.6-306 0L410.3 307.6a8.03 8.03 0 000 11.3l39.7 39.7c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c53.8-53.8 144.6-59.5 204 0 59.5 59.5 53.8 150.2 0 204L665.3 562.6a8.03 8.03 0 000 11.3l39.8 39.8c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c84.5-84.6 84.5-221.5 0-306.1zM610.1 372.3a8.03 8.03 0 00-11.3 0L372.3 598.7a8.03 8.03 0 000 11.3l39.6 39.6c3.1 3.1 8.2 3.1 11.3 0l226.4-226.4c3.1-3.1 3.1-8.2 0-11.3l-39.5-39.6z" />
                  </svg>
                </span>Flight View
              </a>
              <a href="http://travel.state.gov/" target="_blank">
                <span
                  class="bg-indigo-100 text-ipurple-500 w-5 h-5 mr-2 rounded-full inline-flex items-center justify-center">
                  <svg aria-hidden="true" style="-ms-transform:rotate(360deg);-webkit-transform:rotate(360deg)"
                    transform="rotate(360)" fill="none" stroke="currentColor" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="3" class="w-3 h-3" viewBox="0 0 1024 1024">
                    <defs />
                    <path fill="#626262"
                      d="M574 665.4a8.03 8.03 0 00-11.3 0L446.5 781.6c-53.8 53.8-144.6 59.5-204 0-59.5-59.5-53.8-150.2 0-204l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3l-39.8-39.8a8.03 8.03 0 00-11.3 0L191.4 526.5c-84.6 84.6-84.6 221.5 0 306s221.5 84.6 306 0l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3L574 665.4zm258.6-474c-84.6-84.6-221.5-84.6-306 0L410.3 307.6a8.03 8.03 0 000 11.3l39.7 39.7c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c53.8-53.8 144.6-59.5 204 0 59.5 59.5 53.8 150.2 0 204L665.3 562.6a8.03 8.03 0 000 11.3l39.8 39.8c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c84.5-84.6 84.5-221.5 0-306.1zM610.1 372.3a8.03 8.03 0 00-11.3 0L372.3 598.7a8.03 8.03 0 000 11.3l39.6 39.6c3.1 3.1 8.2 3.1 11.3 0l226.4-226.4c3.1-3.1 3.1-8.2 0-11.3l-39.5-39.6z" />
                  </svg>
                </span>General Travel Information
              </a>
              <a href="https://cibtvisas.com/visas" target="_blank">
                <span
                  class="bg-indigo-100 text-ipurple-500 w-5 h-5 mr-2 rounded-full inline-flex items-center justify-center">
                  <svg aria-hidden="true" style="-ms-transform:rotate(360deg);-webkit-transform:rotate(360deg)"
                    transform="rotate(360)" fill="none" stroke="currentColor" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="3" class="w-3 h-3" viewBox="0 0 1024 1024">
                    <defs />
                    <path fill="#626262"
                      d="M574 665.4a8.03 8.03 0 00-11.3 0L446.5 781.6c-53.8 53.8-144.6 59.5-204 0-59.5-59.5-53.8-150.2 0-204l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3l-39.8-39.8a8.03 8.03 0 00-11.3 0L191.4 526.5c-84.6 84.6-84.6 221.5 0 306s221.5 84.6 306 0l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3L574 665.4zm258.6-474c-84.6-84.6-221.5-84.6-306 0L410.3 307.6a8.03 8.03 0 000 11.3l39.7 39.7c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c53.8-53.8 144.6-59.5 204 0 59.5 59.5 53.8 150.2 0 204L665.3 562.6a8.03 8.03 0 000 11.3l39.8 39.8c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c84.5-84.6 84.5-221.5 0-306.1zM610.1 372.3a8.03 8.03 0 00-11.3 0L372.3 598.7a8.03 8.03 0 000 11.3l39.6 39.6c3.1 3.1 8.2 3.1 11.3 0l226.4-226.4c3.1-3.1 3.1-8.2 0-11.3l-39.5-39.6z" />
                  </svg>
                </span>Visa Assistance
              </a>
              <a href="https://cibtvisas.com/passport-information" target="_blank">
                <span
                  class="bg-indigo-100 text-ipurple-500 w-5 h-5 mr-2 rounded-full inline-flex items-center justify-center">
                  <svg aria-hidden="true" style="-ms-transform:rotate(360deg);-webkit-transform:rotate(360deg)"
                    transform="rotate(360)" fill="none" stroke="currentColor" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="3" class="w-3 h-3" viewBox="0 0 1024 1024">
                    <defs />
                    <path fill="#626262"
                      d="M574 665.4a8.03 8.03 0 00-11.3 0L446.5 781.6c-53.8 53.8-144.6 59.5-204 0-59.5-59.5-53.8-150.2 0-204l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3l-39.8-39.8a8.03 8.03 0 00-11.3 0L191.4 526.5c-84.6 84.6-84.6 221.5 0 306s221.5 84.6 306 0l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3L574 665.4zm258.6-474c-84.6-84.6-221.5-84.6-306 0L410.3 307.6a8.03 8.03 0 000 11.3l39.7 39.7c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c53.8-53.8 144.6-59.5 204 0 59.5 59.5 53.8 150.2 0 204L665.3 562.6a8.03 8.03 0 000 11.3l39.8 39.8c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c84.5-84.6 84.5-221.5 0-306.1zM610.1 372.3a8.03 8.03 0 00-11.3 0L372.3 598.7a8.03 8.03 0 000 11.3l39.6 39.6c3.1 3.1 8.2 3.1 11.3 0l226.4-226.4c3.1-3.1 3.1-8.2 0-11.3l-39.5-39.6z" />
                  </svg>
                </span>Passport Assistance
              </a>
            </nav>
          </div>
          <div class="lg:p-4 lg:w-1/3 w-full">
            <h2 class="font-medium title-font tracking-widest text-gray-900 mb-4 text-sm text-left"></h2>
            <nav class="flex flex-col items-start text-left -mb-1 space-y-2.5 text-xl font-bold">
              <a href="http://tripadvisor.com/" target="_blank">
                <span
                  class="bg-indigo-100 text-ipurple-500 w-5 h-5 mr-2 rounded-full inline-flex items-center justify-center">
                  <svg aria-hidden="true" style="-ms-transform:rotate(360deg);-webkit-transform:rotate(360deg)"
                    transform="rotate(360)" fill="none" stroke="currentColor" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="3" class="w-3 h-3" viewBox="0 0 1024 1024">
                    <defs />
                    <path fill="#626262"
                      d="M574 665.4a8.03 8.03 0 00-11.3 0L446.5 781.6c-53.8 53.8-144.6 59.5-204 0-59.5-59.5-53.8-150.2 0-204l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3l-39.8-39.8a8.03 8.03 0 00-11.3 0L191.4 526.5c-84.6 84.6-84.6 221.5 0 306s221.5 84.6 306 0l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3L574 665.4zm258.6-474c-84.6-84.6-221.5-84.6-306 0L410.3 307.6a8.03 8.03 0 000 11.3l39.7 39.7c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c53.8-53.8 144.6-59.5 204 0 59.5 59.5 53.8 150.2 0 204L665.3 562.6a8.03 8.03 0 000 11.3l39.8 39.8c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c84.5-84.6 84.5-221.5 0-306.1zM610.1 372.3a8.03 8.03 0 00-11.3 0L372.3 598.7a8.03 8.03 0 000 11.3l39.6 39.6c3.1 3.1 8.2 3.1 11.3 0l226.4-226.4c3.1-3.1 3.1-8.2 0-11.3l-39.5-39.6z" />
                  </svg>
                </span>Trip Advice | Feedback
              </a>
              <a href="http://worldtimezone.net/" target="_blank">
                <span
                  class="bg-indigo-100 text-ipurple-500 w-5 h-5 mr-2 rounded-full inline-flex items-center justify-center">
                  <svg aria-hidden="true" style="-ms-transform:rotate(360deg);-webkit-transform:rotate(360deg)"
                    transform="rotate(360)" fill="none" stroke="currentColor" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="3" class="w-3 h-3" viewBox="0 0 1024 1024">
                    <defs />
                    <path fill="#626262"
                      d="M574 665.4a8.03 8.03 0 00-11.3 0L446.5 781.6c-53.8 53.8-144.6 59.5-204 0-59.5-59.5-53.8-150.2 0-204l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3l-39.8-39.8a8.03 8.03 0 00-11.3 0L191.4 526.5c-84.6 84.6-84.6 221.5 0 306s221.5 84.6 306 0l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3L574 665.4zm258.6-474c-84.6-84.6-221.5-84.6-306 0L410.3 307.6a8.03 8.03 0 000 11.3l39.7 39.7c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c53.8-53.8 144.6-59.5 204 0 59.5 59.5 53.8 150.2 0 204L665.3 562.6a8.03 8.03 0 000 11.3l39.8 39.8c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c84.5-84.6 84.5-221.5 0-306.1zM610.1 372.3a8.03 8.03 0 00-11.3 0L372.3 598.7a8.03 8.03 0 000 11.3l39.6 39.6c3.1 3.1 8.2 3.1 11.3 0l226.4-226.4c3.1-3.1 3.1-8.2 0-11.3l-39.5-39.6z" />
                  </svg>
                </span>World Time Zone Map
              </a>
            </nav>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap -m-2">
        <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
          <div class="flex rounded-lg h-full bg-gray-100 p-8 flex-col">
            <div class="flex items-center mb-3">
              <div class="w-20 mr-3 inline-flex items-center justify-center text-white flex-shrink-0">
                <img src="../assets/new-cdc-logo.gif" class="w-20">
              </div>
              <h2 class="text-gray-900 text-lg title-font font-medium">CDC Information</h2>
            </div>
            <div class="flex-grow lg:ml-0 lg:self-center ml-4 self-start">
              <a href="https://wwwnc.cdc.gov/travel/notices" class="mt-3 text-purple-950 inline-flex items-center">Visit
                Site
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
          <div class="flex rounded-lg h-full bg-gray-100 p-8 flex-col">
            <div class="flex items-center mb-3">
              <div class="w-2/4 mr-3 inline-flex items-center justify-center text-white flex-shrink-0">
                <img src="../assets/new-homeland-logo.gif" class="w-full">
              </div>
              <h2 class="text-gray-900 text-lg title-font font-medium">Homeland Security</h2>
            </div>
            <div class="flex-grow lg:ml-0 lg:self-center ml-4 self-start">
              <a href="https://www.dhs.gov/news/2020/03/11/homeland-security-acting-secretary-chad-f-wolf-s-statement-presidential-proclamation"
                class="mt-3 text-purple-950 inline-flex items-center">Visit Site
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div class="lg:mb-0 lg:w-1/3 mb-10 md:w-1/2 p-2 w-full">
          <div class="flex rounded-lg h-full bg-gray-100 p-8 flex-col">
            <div class="flex items-center mb-3">
              <div class="w-1/2 mr-3 inline-flex items-center justify-center text-white flex-shrink-0">
                <img src="../assets/new-tsa-logo.gif" class="w-full">
              </div>
              <h2 class="text-gray-900 text-lg title-font font-medium">TSA Information</h2>
            </div>
            <div class="flex-grow lg:ml-0 lg:self-center ml-4 self-start">
              <a href="https://www.tsa.gov/coronavirus" class="mt-3 text-purple-950 inline-flex items-center">Visit Site
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="custom-shape-divider-bottom-1624305197 lg:-mb-24">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path
          d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
          class="shape-fill"></path>
      </svg>
    </div>
  </section>
</template>
<script>
  export default {
    name: "Resources",
    components: {
      // HelloWorld,
    },
  };
</script>
<style scoped>
  .custom-shape-divider-top-1624304978 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
  }

  .custom-shape-divider-top-1624304978 svg {
    position: relative;
    display: block;
    width: calc(146% + 1.3px);
    height: 98px;
    transform: rotateY(180deg);
  }

  .custom-shape-divider-top-1624304978 .shape-fill {
    fill: #1D156B;
  }

  .custom-shape-divider-bottom-1624305197 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
  }

  .custom-shape-divider-bottom-1624305197 svg {
    position: relative;
    display: block;
    width: calc(146% + 1.3px);
    height: 98px;
    transform: rotateY(180deg);
  }

  .custom-shape-divider-bottom-1624305197 .shape-fill {
    fill: #1D156B;
  }
</style>